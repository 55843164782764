.StartMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: #39393c;
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: #FFF2AC;
}

.StartOuterMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  max-width: 800px;
  height: 100%;
}

.StartTopMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
}

.StartInnerMobile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 35px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 6px;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: visible;
}

.StartInnerInfoMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin-left: 5%;
}

.StartInnerInfoDividerMobile {
  height: 2px;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;
  background-color: #39393c;
}

.StartInnerTitleMobile {
  font-size: 36px;
  width: 100%;
  color: #39393c;
  text-align: center;
  line-height: 120%;
  margin: 0;
  font-family: 'Kensington', sans-serif;
    letter-spacing: 1px;
}

.StartInnerTextMobile {
  font-size: 18px;
  width: 100%;
  color: #39393c;
  text-align: center;
  margin: 0;
}

.StartInnerInfoButtonsMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 0px;
}

.StartInnerLogoMobile {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: -4%;
  height: 100%;
  overflow: visible;
}

.StartInnerLogoOverlayMobile {
  height: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: -100px;
  z-index: 10;
}

.AppHeaderLogoMobile {
    width: 100px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-bottom: -10px;
}

@media (max-width: 800px) {
    .StartInnerTitleMobile {
        font-size: 30px;
    }

    .StartInnerInfoDividerMobile {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .StartInnerTextMobile {
        font-size: 12px;
    }

}
@media (max-width: 600px) {
    .StartInnerTitleMobile {
        font-size: 26px;
    }

    .StartInnerInfoDividerMobile {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .StartInnerTextMobile {
        font-size: 11px;
    }

}

@media (max-width: 500px) {
    .StartInnerTitleMobile {
        font-size: 22px;
    }

    .StartInnerTextMobile {
        font-size: 10px;
    }

.StartInnerLogoOverlayMobile {
    margin-top: -80px;
}
.StartTopMobile {
    margin-top: 40px;
    margin-bottom: -10px;
}

}

@media (max-width: 400px) {
    .StartInnerTitleMobile {
        font-size: 20px;
    }

    .StartInnerInfoDividerMobile {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .StartInnerTextMobile {
        font-size: 9px;
    }

}

