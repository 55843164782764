.AchievementsPreviewMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 90%;
  max-width: 800px;
}

.AchievementsPreviewInnerMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
    flex-wrap: wrap;
  width: 77%;
}

.AchievementsPreviewItemMobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(48% - 20px);
  background-color: #ffffff;
  border: 1px solid #39393c;
  border-radius: 6px;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
box-shadow: 0px 1.5px 1px #39393C;
}

.AchievementsPreviewItemImgMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 3px;
  overflow: hidden;
}

.AchievementsPreviewItemInnerMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-left: 10px;
    overflow: hidden;
}

.AchievementsPreviewItemTitleMobile {
    text-align: left;
    flex: 1;
    color: #39393c;
    font-size: 12px;
    font-weight: 700;
    margin-right: 10px;
    margin: 0;
}

.AchievementsPreviewItemTextMobile {
    text-align: right;
    color: #47CCF6;
    font-size: 9px;
    font-weight: 600;
    margin: 0;
    margin-right: 10px;
    margin-bottom: -3px;
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.AchievementsLeaderItemMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #A6E7CF;
    border: 1px solid #39393c;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 20%;
    margin-top: 15px;
    box-shadow: 0px 1.5px 1px #39393C;
}

.AchievementsLeaderItemTitleOuterMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-bottom: 10px;
}

.AchievementsLeaderItemTitleArrowOuterMobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 15px;
}

.AchievementsLeaderItemTitleMobile {
    color: #39393c;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
    text-align: center;
    width: 80%;
    text-decoration: underline;
}

.AchievementsLeaderItemImgMobile {
    width: 50%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AchievementsLeaderItemNameMobile {
    color: #39393c;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    text-align: center;
    width: 80%;
    margin-bottom: 10px;
}

.AchievementsLeaderItemPointsMobile {
    color: #39393c;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    text-align: center;
    width: 80%;
    background-color: #FFFBF3;
    border: 1px solid #39393c;
    border-radius: 6px;
    padding-top: 5px;
    padding-bottom: 5px;
}

@media (max-width: 800px) {

.AchievementsPreviewItemTitleMobile {
    font-size: 9px;
  }

  .AchievementsPreviewItemTextMobile {
    font-size: 7px;
    max-width: 40%;
  }
  
}

@media (max-width: 600px) {

    .AchievementsPreviewMobile {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  
    .AchievementsPreviewInnerMobile {
      width: 100%;
    }

.AchievementsLeaderItemMobile {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 50%;
  margin-top: 40px;
}
}
@media (max-width: 500px) {

  .AchievementsPreviewItemTitleMobile {
    font-size: 12px;
  }

  .AchievementsPreviewItemTextMobile {
    font-size: 10px;
    max-width: 50%;
  }

    .AchievementsPreviewItemMobile {
      width: calc(100% - 20px);
    }
.AchievementsLeaderItemMobile {
  margin-top: 40px;
  width: 70%;
}
.AchievementsLeaderItemTitleMobile {
  font-size: 14px;
}
.AchievementsLeaderItemNameMobile {
  font-size: 14px;
}

.AchievementsLeaderItemPointsMobile {
  font-size: 14px;
}
}

@media (max-width: 400px) {

  .AchievementsPreviewItemTitleMobile {
    font-size: 12px;
  }

  .AchievementsPreviewItemTextMobile {
    font-size: 9px;
    max-width: 40%;
  }

  .AchievementsLeaderItemMobile {
    width: calc(100% - 2px);
  }
}