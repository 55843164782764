.Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  color: #39393c;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fffbf3;
  min-height: calc(50vh - 100px);
}

.InfoInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 72%;
  max-width: 1400px;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: visible;
}

.InfoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;
}

.InfoRowObject {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15%;
}

.InfoRowObjectInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background-color: #fffbf3;
  box-shadow: 2px 2px 5px rgba(35, 35, 35, 0.2);
  border-radius: 5px;
  margin-top: 20px;
  height: 220px;
  cursor: pointer;
}

.InfoRowObjectInnerFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: #FFF2AC;
  border-radius: 0px 0px 5px 5px;
  margin-top: 0px;
}

.InfoRowObjectInnerAuthor {
  font-size: 8px;
  color: #39393c;
  flex: 1;
  margin-left: 3%;
  margin-right: 3%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 94%;
}
.InfoRowObjectInnerTitle {
  font-size: 10px;
  font-weight: 600;
  color: #39393c;
  width: 94%;
  height: 30px;
  margin-left: 3%;
  margin-top: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.markdownOuterPreview {
  color: #39393c;
  padding: 0;
  margin: 0;
  width: 94%;
  margin-left: 3%;
  margin-top: 0px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex: 1;
  white-space: pre-line;
  overflow: hidden;
  padding-bottom: 6px;
  font-size: 8px;
}

.markdownOuterPreview p {
  font-size: 8px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuterPreview figcaption {
  font-size: 8px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuterPreview h1 {
  font-size: 9px;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuterPreview h2 {
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuterPreview h3 {
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuterPreview h4 {
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuterPreview h5 {
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuterPreview h6 {
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuterPreview img {
  width: 80%;
  height: auto;
  margin-left: 10%;
  margin-top: 0px;
  margin-bottom: 4px;
}


@media (max-width: 1400px) {
  .InfoRow {
      margin-top: 0px;
    }
  .InfoRowObject {
      width: 32%;
      margin-top: 50px;
    }
         .InfoRowObjectInner {
           height: 190px;
         }
  
}

@media (max-width: 800px) {

  .InfoRowObject {
    width: 48%;
    margin-top: 40px;
  }

    .InfoRowObjectInner {
      height: 160px;
    }

}