.FooterMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    color: #39393c;
    padding-top: 50px;
    padding-bottom: 10px;
    background-color: #F5F2EB;
    position: relative;
}

.FooterHeaderMobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: visible;
        width: 90%;
        max-width: 800px;
        position: absolute;
        top: -78px;
}
.FooterInnerMobile {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 80%;
    max-width: 700px;
    padding-top: 30px;
    padding-bottom: 60px;
    overflow: visible;
    flex: 1;
    margin-top: 100px;
}

.FooterInnerInfoMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
}

.FooterSocialsMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 20px;
    margin-left: -3px;
    overflow: visible;
}

.FooterInnerButtonOuterMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
}
.FooterInnerButtonMobile {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #FFFBF3;
        border: 1px solid #39393c;
        border-radius: 6px;
        margin-top: 30px;
        margin-bottom: 5px;
}

.FooterInnerButtonTitleMobile {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
}

.FormOuterMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
}

.FormDescriptionMobile {
    font-weight: 400;
    font-size: 7px;
    width: 95%;
    text-align: left;
    color: #39393c;
    margin-left: 5px;
    min-height: 10px;
    margin-top: 2px;
}
.FooterLinksMobile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

}

.FooterLinkMobile {
    font-size: 9px;
    font-weight: 400;
    color: #39393c;
    margin-right: 10px;
    max-width: 30%;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media (max-width: 700px) {
.FooterHeaderMobile {
        top: -54px;
    }
    .FooterInnerMobile {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 70px;
        }

.FooterInnerInfoMobile {
    width: 100%;
}
.FooterInnerButtonOuterMobile {
    width: 100%;
}
.FooterSocialsMobile {
    width: 40%;
    margin-left: -3%;
}
}