.searchInputOuterMobile {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  margin-top: 26px;
}

.autocompleteResultHeaderMobile {
  font-size: 90%;
  font-weight: 700;
  color: rgba(57, 57, 60, 0.8);
  text-align: left;
  padding: 10px;
  margin: 0;
  background-color: #fdfcf8;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.autocompleteResultTitleMobile {
  font-size: 105%;
  font-weight: 500;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-right: 16px;
}

.autocompleteResultTextMobile {
  font-size: 95%;
  font-weight: 400;
  color: rgba(57, 57, 60, 0.8);
  text-align: right;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.listingCountMobile {
  width: 100%;
  background-color: #fffbf3;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(35, 35, 35, 0.2);
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #39393c;
  margin-bottom: 30px;
}

.listingCountTitleMobile {
  font-size: 5vw;
  font-weight: 600;
  color: #39393c;
  text-align: right;
  min-width: 16%;
  padding: 0;
  margin: 0;
  margin-left: 14px;
  margin-right: 10px;
}

.listingCountSubMobile {
  font-size: 3vw;
  font-weight: 400;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
}

.listingTableMobile {
  width: 88%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  margin-top: -70px;
  margin-bottom: -70px;
}

.listingTableTopMobile {
  width: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  background-color: #fff2ac;
  border: 1px solid #39393c;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 2px 1px #39393c;
}

.listingTableMobile .listingCountTitleMobile {
  width: 94%;
  text-align: left;
  font-size: 1.5vw;
}

.listingTableTitleMobile {
  font-size: 35px;
  font-weight: 600;
  color: #39393c;
  width: 94%;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-left: 14px;
  margin-right: 10px;
}

.listingTableSubMobile {
  width: 93%;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  color: #39393c;
  margin-top: 0px;
  margin-bottom: 10px;
}

.listingTableButtonsMobile {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.listingTableButtonMobile {
  width: 23%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #39393c;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #39393c;
  border-radius: 20px;
}

.listingTableInnerMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  margin-top: -10px;
}

.listingTableInnerDtMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  padding-top: 14px;
}

.dialogInnerMobile {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  background-color: #fffbf3;
  margin-bottom: 0px;
  flex: 1;
}

.appDialogCloseMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  color: #39393c;
  z-index: 2000;
  background-color: #FFF2AC;
  padding-top: 2px;
  padding-bottom: 0px;
}

.listingDialogMobile {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  margin-top: 40px;
  border-radius: 10px;
}

.loadingDialogMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  flex: 1;
  width: 100%;
}

.listingDialogHeaderMobile {
  width: 86%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding-top: 30px;
  padding-bottom: 15px;
}

.appHeaderLogoMobile {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.listingDialogHeaderNameMobile {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.listingDialogHeaderSocialMobile {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.listingDialogHeaderTitleMobile {
  text-align: left;
  font-size: 4.8vw;
  font-weight: 500;
  color: #39393c;
  padding: 0;
  margin: 0;
  margin-left: 2vw;
  max-width: 68%;
}

.listingDialogHeaderSocialLinkMobile {
  width: 9%;
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.listingDialogHeaderSocialIconMobile {
  width: 100%;
  height: auto;
  opacity: 0.8;
}

.listingWebsiteLinkMobile {
  text-align: left;
  font-size: 3vw;
  font-weight: 500;
  color: #35addb;
  padding: 0;
  margin: 0;
  width: 86%;
  text-decoration: none;
}

.listingDialogBodyMobile {
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  color: #39393c;
  margin-top: 10px;
  background-color: #fffbf3;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(35, 35, 35, 0.2);
  padding-top: 20px;
  padding-bottom: 15px;
}

.listingDialogDescMobile {
  text-align: left;
  font-size: 3.2vw;
  font-weight: 300;
  color: #39393c;
  margin-left: 4%;
  margin-bottom: 20px;
  margin-right: 4%;
  width: 92%;
}

.listingDialogDescSocialMobile {
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4%;
  margin-bottom: 15px;
  margin-right: 4%;
}

.listingDialogTagMobile {
  text-align: center;
  font-size: 3vw;
  font-weight: 400;
  color: #39393c;
  padding: 0px 5px 0px 5px;
  margin-left: 4%;
  border: 1px solid #39393c;
  border-radius: 60px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.radioButtonsMobile {
  width: 91%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 4%;
  margin-right: 5%;
}



@media (max-width: 800px) {
    .listingTableTitleMobile {
        font-size: 20px;
    }

    .listingTableSubMobile {
        font-size: 9px;
    }

    .listingTableButtonMobile {
        font-size: 9px;
    }
}

@media (max-width: 600px) {
    .listingTableTitleMobile {
        font-size: 15px;
    }

    .listingTableSubMobile {
        font-size: 8px;
    }

    .listingTableButtonMobileMobile {
        font-size: 7px;
    }
}
@media (max-width: 500px) {
  .listingTableTitle {
    font-size: 15px;
  }

  .listingTableSubMobile {
    font-size: 8px;
  }

  .listingTableButtonMobile {
    font-size: 7px;
  }
}
@media (max-width: 400px) {
  .listingTableTitleMobile {
    font-size: 15px;
  }

  .listingTableSubMobile {
    font-size: 8px;
  }

  .listingTableButtonMobile {
    font-size: 7px;
  }
}