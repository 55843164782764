.DashboardMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  color: #39393c;
  padding-top: 60px;
  padding-bottom: 120px;
  background-color: #fffbf3;
}

.DashboardInnerMobile {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 35px;
  overflow: visible;
  width: 90%;
  max-width: 800px;
}

.DashboardInnerInfoMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 42%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 3%;
  padding-right: 3%;
  background-color: #f5f2eb;
  border: 1px solid #39393c;
  border-radius: 6px;
  box-shadow: 0px 2px 1px #39393c;
  margin-bottom: 2px;
}

.DashboardInnerObjectsOuterMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  margin: 0;
  padding: 0;
}

.DashboardPreviewMobile {
  width: 100%;
  margin: 0;
}

@media (max-width: 800px) {
  
.DashboardInnerMobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

.DashboardInnerInfoMobile {
  width: 94%;
}

.DashboardInnerObjectsOuterMobile {
  width: 100%;
  margin-top: 20px;
}
}