.Dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  color: #39393c;
  padding-top: 50px;
  padding-bottom: 90px;
  background-color: #fffbf3;
  min-height: calc(100vh - 100px);
}

.DashboardInner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 35px;
  overflow: visible;
  width: 78%;
  max-width: 1500px;
}

.DashboardInnerInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 42%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 3%;
  padding-right: 3%;
  background-color: #f5f2eb;
  border: 1px solid #39393c;
  border-radius: 6px;
  box-shadow: 0px 2px 1px #39393c;
  margin-bottom: 2px;
}

.DashboardInnerObjectsOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  margin: 0;
  padding: 0;
}

.DashboardPreview {
  width: 100%;
  margin: 0;
}
