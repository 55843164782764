.Listings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    color: #39393c;
    padding-top: 120px;
    padding-bottom: 30px;
    background-color: #fffbf3;
    min-height: calc(90vh - 100px);
}

.ListingsInner {
    display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 78%;
        max-width: 1500px;
        background-color: #F5F2EB;
        border: 1px solid #39393c;
        border-radius: 6px;
        padding-top: 30px;
        padding-bottom: 30px;
        overflow: visible;
}

.ListingsInnerTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}