.StartStats {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 90%;
  margin-top: 0px;
  background-color: #f5f2eb;
  border: 1px solid #39393c;
  border-radius: 6px;
  padding-top: 50px;
  padding-bottom: 60px;
  padding-left: 5%;
  padding-right: 5%;
  overflow: visible;
}

.StartStatsInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 28%;
}

.StartStatsTitle {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0px;
}

.ProjectPreviewOuter {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3%;
  padding-right: 3%;
}

.ProjectPreviewImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.ProjectPreviewInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin-left: 5%;
}

.ProjectPreviewTitle {
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ProjectPreviewText {
  font-size: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ReviewsPreviewOuter {
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3%;
  padding-right: 3%;
  flex: 1;
}

.ReviewsPreviewInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
}

.ReviewsPreviewSub {
  font-size: 8px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ReviewsPreviewText {
  font-size: 9px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  /* number of lines to show */
  line-clamp: 8;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}

.RatingsPreviewOuter {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3%;
  padding-right: 3%;
  flex: 1;
}

@media (max-width: 1800px) {
  .StartStatsTitle {
    font-size: 28px;
  }
  .ProjectPreviewImg {
    width: 37px;
    height: 37px;
  }

  .ProjectPreviewTitle {
    font-size: 9px;
  }

  .ProjectPreviewText {
    font-size: 7px;
  }
  .ReviewsPreviewSub {
    font-size: 7px;
  }

  .ReviewsPreviewText {
    font-size: 8px;
  }
}

@media (max-width: 1200px) {
  .StartStatsTitle {
    font-size: 22px;
  }

  .ProjectPreviewImg {
    width: 30px;
    height: 30px;
  }

  .ProjectPreviewTitle {
    font-size: 8px;
  }

  .ProjectPreviewText {
    font-size: 7px;
  }

  .ReviewsPreviewSub {
    font-size: 7px;
  }

  .ReviewsPreviewText {
    font-size: 7px;
  }
}

@media (max-width: 900px) {
  .StartStatsTitle {
    font-size: 15px;
  }

  .ProjectPreviewImg {
    width: 25px;
    height: 25px;
  }

  .ProjectPreviewTitle {
    font-size: 7px;
  }

  .ProjectPreviewText {
    font-size: 6px;
  }

  .ReviewsPreviewSub {
    font-size: 6px;
  }

  .ReviewsPreviewText {
    font-size: 6px;
  }
}

@media (max-width: 600px) {
    .StartStatsTitle {
        font-size: 11px;
    }

    .ProjectPreviewImg {
        width: 20px;
        height: 20px;
    }

    .ProjectPreviewTitle {
        font-size: 6px;
    }

    .ProjectPreviewText {
        font-size: 5px;
    }

    .ReviewsPreviewSub {
        font-size: 5px;
    }

    .ReviewsPreviewText {
        font-size: 5px;
    }
}
