.MainMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    color: #39393c;
    margin-top: -50px;
    padding-bottom: 0px;
    background-color: #FFF2AC;
    min-height: calc(100vh - 100px);
}