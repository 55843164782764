.innerLogo {
  width: 100%;
  height: auto;
}

.parallax {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  position: absolute;
}

.rateIcon {
  width: 100%;
  height: 100%;
  position: relative;
}

.rateIconInner {
  position: absolute;
  opacity: 1;
}

.startLogo {
  width: 100%;
  height: auto;
}

.reviewLogo {
  width: 100%;
  height: auto;
  position: absolute;
}

.buttonLogo {
  width: 100%;
  height: auto;
}

.reviewAnimationOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1/1.2;
  position: relative;
}

.animationOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
}

.appHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  margin: 0;
  top: 0;
  color: #39393c;
  padding: 0px;
    z-index: 1000;
  background-color: transparent;
  padding-top: 0px;
  padding-bottom: 0px;
}

.appHeaderInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 78%;
  max-width: 1500px;
  color: #39393c;
  padding: 0px;
}

.appHeaderLogo {
  width: 80px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
}

.appMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  color: #39393c;
  flex: 1;
  padding-top: 50px;
  padding-bottom: 50px;
}

.appMenuClose {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 4px;
  color: #39393c;
  z-index: 2000;
}

.searchInputOuter {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  margin-top: 5px;
}

.appMenuHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  flex: 1;
  margin-bottom: 20px;
}

.appMenuHeaderLogo {
  width: 60%;
  margin-bottom: 2%;
}

.appMenuHeaderSubtitle {
  font-weight: 400;
  font-size: 3.9vw;
  width: 90%;
  text-align: center;
  color: #39393c;
  letter-spacing: 2px;
}

.socialsOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  flex: 1;
  margin-bottom: 20px;
  overflow: visible;
}

.socialsOuter .appMenuHeaderSubtitle {
  margin-bottom: 50px;
  margin-top: 50px;
  letter-spacing: unset;
  font-weight: 600;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 96%;
  overflow: visible;
}

.socialLink {
  width: 22%;
  overflow: visible;
  max-width: 100px;
}

.socialIcon {
  width: 100%;
  height: auto;
  overflow: visible;
}

.socialsLogoSvg {
  width: 100%;
  aspect-ratio: 1;
}

.appMenuLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 68%;
  flex: 1;
  color: #39393c;
  margin-bottom: 20px;
}

.appMenuLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  flex: 1;
}
.appMenuLinkAlt {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 3vw;
  letter-spacing: 1px;
  text-align: center;
  width: 100%;
}

.dialogInner {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  background-color: #fff5e3;
  margin-bottom: 40px;
  flex: 1;
  position: relative;
}

.appDialogClose {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: absolute;
  margin-left: 5%;
  margin-right: 5%;
  top: 2px;
  color: #39393c;
  padding: 2px;
  z-index: 2000;
}

.dialogBottom {
  min-height: 140px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffd379;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #39393c;
}

.footerText {
  margin: 0;
  font-weight: 300;
  text-align: center;
  font-size: 8px;
}

@media (min-width: 350px) {
  .appMenuHeader {
    width: 63%;
  }
  .appMenuHeaderSubtitle {
    font-size: 3vw;
  }

  .socialsOuter {
    width: 55%;
  }

  .appMenuLogo {
    width: 50%;
  }

  .appMenuLinkAlt {
    font-size: 2.6vw;
  }
}

@media (min-width: 550px) {
  .appMenuHeader {
    width: 50%;
  }

  .appMenuHeaderSubtitle {
    font-size: 2.5vw;
  }

  .socialsOuter {
    width: 48%;
  }

  .appMenuLogo {
    width: 40%;
  }

  .appMenuLinkAlt {
    font-size: 2vw;
  }
}

@media (min-width: 700px) {
  .appMenuHeader {
    width: 40%;
  }

  .appMenuHeaderSubtitle {
    font-size: 2vw;
  }

  .socialsOuter {
    width: 36%;
  }

  .appMenuLogo {
    width: 28%;
  }

  .appMenuLinkAlt {
    font-size: 1.7vw;
  }
}

@media (min-width: 900px) {
  .dialogInner {
    width: 600px;
    min-height: 80vh;
  }

  .appDialogClose {
    position: unset;
  }
}

@media (min-width: 900px) {
  .appMenu {
    width: 400px;
  }

  .appMenuHeader {
    width: 63%;
  }

  .socialsOuter {
    width: 55%;
  }

  .appMenuHeaderSubtitle {
    font-size: 12px;
  }

  .appMenuLinkAlt {
    font-size: 12px;
  }

  .appMenuClose {
    width: 380px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .appMenuLogo {
    width: 50%;
  }
}
