.StartSearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: #39393c;
  background-color: #fff2ac;
  margin-top: 30px;
  margin-bottom: 60px;
}

.StartSearchOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 78%;
  max-width: 1500px;
  height: 100%;
}

.StartSearchTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 6px 6px 0px 0px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.StartSearchTitle {
  font-size: 38px;
  width: 90%;
  color: #39393c;
  text-align: center;
  line-height: 140%;
  margin: 0;
  font-family: "Kensington", sans-serif;
  letter-spacing: 1px;
  margin-bottom: -3px;
}

.StartSearchText {
  font-size: 16px;
  width: 90%;
  color: #39393c;
  text-align: center;
  margin: 0;
}

.StartSearchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 900px;
  margin-top: 40px;
}

.StartSearchResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  background-color: #f5f2eb;
  border: 1px solid #39393c;
  border-radius: 0px 0px 6px 6px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-width: 0px;
}

.StartSearchResultsInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 98%;
  max-width: 1500px;
  flex-wrap: wrap;
}

.StartSearchResultsItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(33% - 40px);
  margin: 10px;
  background-color: #fdfbf7;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 2px 4px 0px rgba(74, 74, 74, 0.05);
}

.StartSearchResultsItemTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100%);
  margin-top: 10px;
}

.StartSearchResultsItemTopTextOuter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
}

.StartSearchResultsItemTopTextInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.StartSearchResultsItemImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.StartSearchResultsItemTitle {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.StartSearchResultsItemCategory {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: right;
    text-decoration: none;
    margin: 0;
    margin-left: 0px;
    margin-right: 5px;
    margin-top: 0x;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.StartSearchResultsItemText {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
    line-height: 1.4;
    width: 100%;
}

.StartSearchResultsItemBadge {
  width: 14px;
  min-width: 14px;
  margin-left: 5px;
  margin-top: 4px;
  margin-bottom: 0px;
  overflow: visible;
}

.StartSearchResultsItemBadgeRugged {
  width: 18px;
  min-width: 18px;
  margin-left: 5px;
  overflow: visible;
  margin-top: 4px;
  margin-bottom: 0px;
}

.StartSearchResultsExpandButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-top: 10px;
  background-color: #fdfbf7;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 2px 4px 0px rgba(74, 74, 74, 0.05);
  cursor: pointer;
}

.ProjectListRatingItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.ProjectListRatingItemInner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.ProjectListRatingItemRating {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}

.ProjectListRatingItemRatingText {
    font-size: 9px;
    font-weight: 500;
    margin: 0px;
    margin-right: 3px;
    margin-left: 3px;
    white-space: nowrap;
    text-decoration: none;
    text-align: left;
}

.ProjectListRatingItemRatingTextAlt {
    font-size: 9px;
    font-weight: 500;
    margin: 0px;
    margin-right: 3px;
    margin-left: 3px;
    white-space: nowrap;
    text-decoration: none;
    text-align: left;
    margin-top: 6.5px;
    margin-bottom: 4px;
}

.ProjectListRatingItemRating svg {
    width: 8px;
    margin-top: 1px;
}

@media (max-width: 1800px) {
  .StartSearchTitle {
    font-size: 34px;
  }
  .StartSearchText {
    font-size: 14px;
  }
}

@media (max-width: 1500px) {
  .StartSearchTitle {
    font-size: 30px;
  }
  .StartSearchText {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .StartSearchTitle {
    font-size: 26px;
  }
  .StartSearchText {
    font-size: 10px;
  }
}

@media (max-width: 1000px) {
  .StartSearchTitle {
    font-size: 24px;
  }
  .StartSearchText {
    font-size: 9px;
  }
  .StartSearchResultsItem {
      width: calc(49% - 40px);
      margin: 10px
  }
  .StartSearchInput {
      width: 70%;
  }
}

@media (max-width: 900px) {
  .StartSearchTitle {
    font-size: 22px;
  }
  .StartSearchText {
    font-size: 9px;
  }
}

@media (max-width: 800px) {
  .StartSearchTitle {
    font-size: 20px;
  }
  .StartSearchText {
    font-size: 10px;
  }
}

@media (max-width: 600px) {
  .StartSearchTitle {
    font-size: 18px;
  }
  .StartSearchText {
    font-size: 10px;
  }
.StartSearchResultsItem {
    width: calc(100% - 40px);
    margin: 10px
}
.StartSearchInput {
    width: 80%;
}
}

@media (max-width: 400px) {
    .StartSearchTitle {
        font-size: 15px;
    }

    .StartSearchText {
        font-size: 9px;
    }

    .StartSearchResultsItem {
        width: calc(100% - 40px);
        margin: 10px
    }
}
