.RoutesOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #fffbf3;
}
.RoutesHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  color: #39393c;
  height: 50px;
  background-color: #FFF2AC;
  position: fixed;
  top: 0;
}

.RoutesInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  max-width: 1500px;
  padding-top: 50px;
  padding-bottom: 50px;
  flex: 1;
}

.RoutesTitle {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
}

.RoutesSubTitle {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.RoutesTextTitle {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;
}

.RoutesText {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}