.ListingsMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  color: #39393c;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #fffbf3;
  min-height: calc(100vh - 100px);
}

.ListingsInnerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 800px;
  background-color: #f5f2eb;
  border: 1px solid #39393c;
  border-radius: 6px;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: visible;
}

.ListingsInnerTableMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 102%;
  margin-top: 100px;
}
