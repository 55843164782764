.searchInputOuter {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  margin-top: 26px;
}

.autocompleteResultHeader {
  font-size: 90%;
  font-weight: 700;
  color: rgba(57, 57, 60, 0.8);
  text-align: left;
  padding: 10px;
  margin: 0;
  background-color: #fdfcf8;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.autocompleteResultTitle {
  font-size: 105%;
  font-weight: 500;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-right: 16px;
}

.autocompleteResultText {
  font-size: 95%;
  font-weight: 400;
  color: rgba(57, 57, 60, 0.8);
  text-align: right;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.listingCount {
  width: 100%;
  background-color: #fffbf3;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(35, 35, 35, 0.2);
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #39393c;
  margin-bottom: 30px;
}

.listingCountTitle {
  font-size: 5vw;
  font-weight: 600;
  color: #39393c;
  text-align: right;
  min-width: 16%;
  padding: 0;
  margin: 0;
  margin-left: 14px;
  margin-right: 10px;
}

.listingCountSub {
  font-size: 3vw;
  font-weight: 400;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
}

.listingTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  background-color: #fffbf3;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(35, 35, 35, 0.2);
  padding-top: 20px;
  padding-bottom: 12px;
}

.listingTable .listingCountTitle {
  width: 94%;
  text-align: left;
}

.listingTableSub {
  width: 93%;
  text-align: left;
  font-size: 3vw;
  font-weight: 600;
  color: #39393c;
  margin-top: 10px;
}

.listingTableButtons {
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.listingTableButton {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3.5vw;
  font-weight: 500;
  color: #39393c;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  cursor: pointer;
}

.listingTableInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  margin-top: 10px;
}
.listingTableInnerDt {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  padding-top: 14px;
}

.listingTableItem {
  width: 86%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #39393c;
  padding: 10px;
  background-color: #fffbf3;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(35, 35, 35, 0.2);
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.listingTableItemTitle {
  font-size: 2.7vw;
  font-weight: 600;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-left: 2vw;
}

.listingTableItemSub {
  font-size: 2.7vw;
  font-weight: 500;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-left: 5vw;
}

.listingTableItemInfo {
  font-size: 2.7vw;
  font-weight: 500;
  color: #393c3a;
  text-align: center;
  flex: 1;
  padding: 0;
  margin: 0;
}

.listingTableItemRating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #393c3a;
  flex: 1;
  padding: 0;
  margin: 0;
}

.listingTableItemRating .listingTableItemInfo {
  flex: unset
}

.dialogInner {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  background-color: #fdf7ea;
  margin-bottom: 40px;
  flex: 1;
}
.listingDialog {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  margin-top: 40px;
  border-radius: 10px;
}

.loadingDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  flex: 1;
  width: 100%;
}

.listingDialogHeaderOuter {
  background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #39393c;
    box-shadow: 0px 1.5px 1px #39393C;
    width: 94%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #39393c;
    margin-top: 20px;
}
.listingDialogHeader {
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding-top: 20px;
  padding-bottom: 15px;
}

.appHeaderLogo {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.listingDialogHeaderName {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.listingDialogHeaderSocial {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.listingDialogHeaderTitle {
  text-align: left;
  font-size: 4.8vw;
  font-weight: 500;
  color: #39393c;
  padding: 0;
  margin: 0;
  margin-left: 2vw;
  max-width: 68%;
}

.listingDialogHeaderSocialLink {
  width: 9%;
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  max-width: 30px;
}

.listingDialogHeaderSocialIcon {
  width: 100%;
  height: auto;
  opacity: 0.8;
}

.listingWebsiteLink {
  text-align: left;
  font-size: 3vw;
  font-weight: 500;
  color: #35addb;
  padding: 0;
  margin: 0;
  width: 94%;
  text-decoration: none;
}

.listingDialogBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  color: #39393c;
  padding-bottom: 15px;
}

.listingDialogDesc {
  text-align: left;
  font-size: 3.2vw;
  font-weight: 300;
  color: #39393c;
  margin-left: 3%;
  margin-bottom: 20px;
  margin-right: 3%;
  width: 94%;
}

.listingDialogDescSocial {
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 3%;
  margin-bottom: 15px;
  margin-right: 3%;
}

.listingDialogTag {
  text-align: center;
  font-size: 3vw;
  font-weight: 400;
  color: #39393c;
  padding: 2px 7px 2px 7px;
  margin-left: 3%;
  border: 1px solid #39393c;
  border-radius: 60px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.radioButtons {
  width: 91%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 4%;
  margin-right: 5%;
}

.listingCountDt {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #39393c;
  flex: 1;
  padding-top: 30px;
}

.listingCountDtInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listingCountTitleDt {
  font-size: 230%;
  font-weight: 700;
  color: #39393c;
  text-align: center;
  width: 90%;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.listingCountSubDt {
  font-size: 110%;
  font-weight: 400;
  color: #39393c;
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: -4px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: -1px;
  padding-bottom: -1px;
}

.listingTableButtonDt {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 80%;
  font-weight: 600;
  color: #39393c;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.listingTableItemTitleDt {
  font-size: 80%;
  font-weight: 600;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-left: 14px;
}

.listingTableItemSubDt {
  font-size: 80%;
  font-weight: 500;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-left: 14px;
}

.listingTableItemInfoDt {
  font-size: 80%;
  font-weight: 500;
  color: #39393c;
  text-align: center;
  flex: 1;
  padding: 0;
  margin: 0;
}

@media (min-width: 350px) {
  .listingCountTitle {
    font-size: 4.3vw;
  }

  .listingCountSub {
    font-size: 2.8vw;
  }
  .listingTableSub {
    font-size: 2.6vw;
  }
  .listingTableButton {
    font-size: 3vw;
  }
  .listingDialogHeaderTitle {
    font-size: 4.2vw;
  }

  .listingDialogHeaderSocialLink {
    width: 8%;
  }

    .listingTableItem {
      width: 88%;
    }

  .listingTableItemTitle {
    font-size: 2.5vw;
  }

  .listingTableItemSub {
    font-size: 2.5vw;
  }
  .listingTableItemInfo {
    font-size: 2.5vw;
  }
  .listingWebsiteLink {
    font-size: 2.6vw;
  }
  .listingDialogDesc {
    font-size: 2.8vw;
  }
  .listingDialogTag {
    font-size: 2.6vw;
  }
  .listingDialogCategory {
    font-size: 2.6vw;
  }
  .radioButtons {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

@media (min-width: 550px) {
  .listingCountTitle {
    font-size: 3.8vw;
  }

  .listingCountSub {
    font-size: 2.4vw;
  }

  .listingTableSub {
    font-size: 2.2vw;
  }
  .listingTableButton {
    font-size: 2vw;
  }
  .listingDialogHeaderTitle {
    font-size: 3.7vw;
  }

    .listingTableItem {
      width: 90%;
    }
  .listingTableItemSub {
    font-size: 2vw;
  }
  .listingDialogHeaderSocialLink {
    width: 7%;
  }

  .listingTableItemInfo {
    font-size: 2vw;
  }
  .listingWebsiteLink {
    font-size: 2.2vw;
  }
  .listingDialogDesc {
    font-size: 2.4vw;
  }
  .listingDialogTag {
    font-size: 2.2vw;
  }
  .listingDialogCategory {
    font-size: 2.2vw;
  }

  .radioButtons {
    padding-top: 56px;
    padding-bottom: 36px;
  }
}

@media (min-width: 701px) {
  .listingCountTitle {
    font-size: 160%;
  }

  .listingCountSub {
    font-size: 100%;
  }

  .listingTableSub {
    font-size: 100%;
  }
  .listingTableButton {
    font-size: 105%;
  }

  .appDialogClose {
    width: 600px;
    border-radius: 3px 3px 0px 0px;
  }

  .listingDialogHeaderTitle {
    font-size: 120%;
  }

  .dialogInner {
    width: 600px;
  }

    .listingTableItem {
      width: 92%;
    }
  .listingTableItemTitle {
    font-size: 100%;
  }

  .listingTableItemSub {
    font-size: 90%;
  }

  .listingTableItemInfo {
    font-size: 90%;
  }
  .listingWebsiteLink {
    font-size: 80%;
  }
  .listingDialogDesc {
    font-size: 90%;
  }
  .listingDialogTag {
    font-size: 85%;
  }
  .listingDialogCategory {
    font-size: 85%;
  }

  .radioButtons {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}

/* Desktop */

@media (min-width: 1000px) {
}

@media (min-width: 1500px) {
}
