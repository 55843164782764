.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    color: #39393c;
    padding-top: 50px;
    padding-bottom: 10px;
    background-color: #F5F2EB;
    min-height: calc(50vh - 60px);
    position: relative;
}

.FooterHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: visible;
        width: 78%;
        max-width: 1500px;
        position: absolute;
        top: -78px;
}
.FooterInner {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 78%;
    max-width: 1400px;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: visible;
    flex: 1;
    margin-top: 100px;
}

.FooterInnerInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 55%;
}

.FooterSocials {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin-top: 20px;
    margin-left: -3px;
}

.FooterInnerButtonOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.FooterInnerButton {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #FFFBF3;
        border: 1px solid #39393c;
        border-radius: 6px;
        margin-top: 30px;
        margin-bottom: 5px;
        flex: 1;
}

.FooterInnerButtonTitle {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
}

.FormOuter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
}

.FormDescription {
    font-weight: 400;
    font-size: 7px;
    width: 95%;
    text-align: left;
    color: #39393c;
    margin-left: 5px;
    min-height: 10px;
    margin-top: 2px;
}

.FooterLinks {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
   
}

.FooterLink {
    font-size: 9px;
    font-weight: 400;
    color: #39393c;
    margin-right: 10px;
    max-width: 30%;
    margin-top: 5px;
    text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
}