.Start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: #39393c;
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: #FFF2AC;
}

.StartOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 78%;
  max-width: 1500px;
  height: 100%;
}

.StartTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
}

.StartInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 35px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 6px;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: visible;
}

.StartInnerInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 55%;
  margin-left: 5%;
}

.StartInnerInfoDivider {
  height: 2px;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;
  background-color: #39393c;
}

.StartInnerTitle {
  font-size: 50px;
  width: 100%;
  color: #39393c;
  text-align: left;
  line-height: 140%;
  margin: 0;
  font-family: 'Kensington', sans-serif;
  letter-spacing: 1px;
}

.StartInnerText {
  font-size: 20px;
  width: 100%;
  color: #39393c;
  text-align: justify;
  margin: 0;
}

.StartInnerInfoButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0px;
}

.StartInnerLogo {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 5%;
  height: 100%;
  overflow: visible;
}

.StartInnerLogoOverlay {
  height: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: -70px;
  margin-bottom: -72px;
  z-index: 10;
}
@media (max-width: 1800px) {
.StartInnerTitle {
        font-size: 45px;
    }

    .StartInnerInfoDivider {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .StartInnerText {
        font-size: 18px;
    }
}

@media (max-width: 1500px) {
    .StartInnerTitle {
        font-size: 40px;
    }

    .StartInnerInfoDivider {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .StartInnerText {
        font-size: 16px;
    }
}
@media (max-width: 1200px) {
    .StartInnerTitle {
        font-size: 34px;
    }

    .StartInnerInfoDivider {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .StartInnerText {
        font-size: 14px;
    }
}

@media (max-width: 1000px) {
    .StartInnerTitle {
        font-size: 28px;
    }

    .StartInnerInfoDivider {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .StartInnerText {
        font-size: 12px;
    }
}
@media (max-width: 900px) {
    .StartInnerTitle {
        font-size: 24px;
    }

    .StartInnerInfoDivider {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .StartInnerText {
        font-size: 10px;
    }
.StartInner {
    padding-top: 30px;
    padding-bottom: 30px;
}
}
@media (max-width: 800px) {
    .StartInnerTitle {
        font-size: 20px;
    }

    .StartInnerInfoDivider {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .StartInnerText {
        font-size: 8px;
    }

}
@media (max-width: 600px) {
    .StartInnerTitle {
        font-size: 16px;
    }

    .StartInnerInfoDivider {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .StartInnerText {
        font-size: 7px;
    }

}