.Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: #39393c;
  margin-top: -50px;
  padding-bottom: 0px;
  background-color: #FFF2AC;
  min-height: calc(100vh - 100px);
}

.AppHeaderLogo {
  width: 110px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  margin-bottom: -10px;
}