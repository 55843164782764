.mainLogo {
  width: 100%;
  height: auto;
}

.mobileLogo {
  width: 100%;
  height: auto;
}

.LazyLoadImgLoading {
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.ScribbleLogo {
    width: 100%;
    height: auto;
    margin-top: -12%;
    margin-bottom: 10px;
}

.HandshakeLogo {
    width: 150px;
    height: auto;
}

.PfpViewer {
    width: 100%;
    aspect-ratio: 1;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PfpViewerImg {
    width: 100%;
    border-radius: 8px;
    height: 100%;
    position: absolute;
}

@media (max-width: 700px) {

    .HandshakeLogo {
            width: 100px;
        }
}