.newsListingsOuter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  margin-bottom: 25px;
  flex: 1;
  min-height: 150px;
}

.loadingDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  flex: 1;
  width: 100%;

  min-height: 150px;
}

.newsListingsText {
  text-align: center;
  font-size: 3vw;
  font-weight: 600;
  color: #999999;
}

.outsideKeyListing {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: visible;
}

.rss3Listing {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  margin-bottom: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2.5px 1px #39393C;
  padding-top: 20px;
  position: relative;
  max-height: 300px;
  transition: all 1s ease-in-out;
}

.rssListingBottomGradient {
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 36px;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7));
}

.rssListingFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
}
.rssListingFooterInner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #636058;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 8px;
    text-decoration: none;
}

.rssListingFooterText {
  font-size: 3.5vw;
  font-weight: 500;
}

.rss3ListingHeader {
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: #39393c;
}

.rss3ListingHeaderInner {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 8px;
  color: #39393c;
}

.rss3ListingTitle {
  text-align: left;
  font-size: 3.5vw;
  font-weight: 600;
  color: #39393c;
  padding: 0;
  margin: 0;
  width: 96%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.rss3ListingSubtitle {
  text-align: left;
  font-size: 2.7vw;
  font-weight: 500;
  color: #8b8b8b;
  padding: 0;
  margin: 0;
  width: 96%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.rss3ListingText {
  text-align: left;
  font-size: 3.2vw;
  font-weight: 400;
  color: #39393c;
  padding: 0;
  margin: 0;
  width: 92%;
  margin-top: 8px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex: 1;
  white-space: pre-line;
  overflow: hidden;
  padding-bottom: 6px;
}

.markdownOuter {
  color: #39393c;
  padding: 0;
  margin: 0;
  width: 92%;
  margin-top: 8px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex: 1;
  white-space: pre-line;
  overflow: hidden;
  padding-bottom: 6px;
  font-size: 3.2vw;
}

.markdownOuter p {
  font-size: 3.2vw;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuter figcaption {
  font-size: 3.2vw;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuter h1 {
  font-size: 3.5vw;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuter h2 {
  font-size: 3.3vw;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuter h3 {
  font-size: 3.3vw;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuter h4 {
  font-size: 3.3vw;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuter h5 {
  font-size: 3.3vw;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuter h6 {
  font-size: 3.3vw;
  font-family: "Poppins", sans-serif;
  margin-top: 4px;
  margin-bottom: 4px;
}

.markdownOuter img {
  width: 80%;
  height: auto;
  margin-left: 10%;
  margin-top: 4px;
  margin-bottom: 4px;
}

@media (min-width: 350px) {
  .newsListingsText {
    font-size: 2.6vw;
  }
  .rss3ListingTitle {
    font-size: 3.1vw;
  }

  .rss3ListingSubtitle {
    font-size: 2.4vw;
  }

  .rss3ListingText {
    font-size: 2.8vw;
    margin-top: 12px;
  }
  .markdownOuter {
    margin-top: 12px;
  }
  .markdownOuter {
    font-size: 2.8vw;
  }
  .markdownOuter p {
    font-size: 2.8vw;
  }

  .markdownOuter figcaption {
    font-size: 2.8vw;
  }

  .markdownOuter h1 {
    font-size: 3.1vw;
  }

  .markdownOuter h2 {
    font-size: 2.9vw;
  }

  .markdownOuter h3 {
    font-size: 2.9vw;
  }

  .markdownOuter h4 {
    font-size: 2.9vw;
  }

  .markdownOuter h5 {
    font-size: 2.9vw;
  }

  .markdownOuter h6 {
    font-size: 2.9vw;
  }
  .rssListingFooterText {
      font-size: 3vw;
  }
}

@media (min-width: 550px) {
  .newsListingsText {
    font-size: 2.2vw;
  }
  .rss3ListingTitle {
    font-size: 2.7vw;
  }

  .rss3ListingSubtitle {
    font-size: 2.1vw;
  }

  .rss3ListingText {
    font-size: 2.4vw;
    margin-top: 16px;
  }

  .markdownOuter {
    margin-top: 16px;
  }
  .markdownOuter {
    font-size: 2.4vw;
  }
  .markdownOuter p {
    font-size: 2.4vw;
  }

  .markdownOuter figcaption {
    font-size: 2.4vw;
  }

  .markdownOuter h1 {
    font-size: 2.7vw;
  }

  .markdownOuter h2 {
    font-size: 2.5vw;
  }

  .markdownOuter h3 {
    font-size: 2.5vw;
  }

  .markdownOuter h4 {
    font-size: 2.5vw;
  }

  .markdownOuter h5 {
    font-size: 2.5vw;
  }

  .markdownOuter h6 {
    font-size: 2.5vw;
  }
  .rssListingFooterText {
      font-size: 2.5vw;
  }
}

@media (min-width: 701px) {
  .newsListingsText {
    font-size: 85%;
  }
  .rss3ListingTitle {
    font-size: 100%;
  }

  .rss3ListingSubtitle {
    font-size: 80%;
  }

  .rss3ListingText {
    font-size: 85%;
    margin-top: 20px;
  }

  .markdownOuter {
    margin-top: 20px;
  }

  .markdownOuter {
    font-size: 85%;
  }
  .markdownOuter p {
    font-size: 85%;
  }

  .markdownOuter figcaption {
    font-size: 85%;
  }

  .markdownOuter h1 {
    font-size: 100%;
  }

  .markdownOuter h2 {
    font-size: 90%;
  }

  .markdownOuter h3 {
    font-size: 90%;
  }

  .markdownOuter h4 {
    font-size: 90%;
  }

  .markdownOuter h5 {
    font-size: 90%;
  }

  .markdownOuter h6 {
    font-size: 90%;
  }
  .rssListingFooterText {
      font-size: 85%;
  }
}
