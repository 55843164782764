@font-face {
  font-family: Kensington;
  src: url("static/Kensington.otf") format("opentype");
}

.App {
  text-align: left;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #39393c;
    width: 100vw;
    font-family: 'Poppins', sans-serif;
    background-color: #FFF2AC;;
}

.AppTemp {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #39393c;
  width: 100vw;
  font-family: 'Poppins', sans-serif;
  background-color: #FFF2AC;
}
.AppTempInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  max-width: 300px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}