.StartStatsMobile {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 90%;
  margin-top: 0px;
  background-color: #f5f2eb;
  border: 1px solid #39393c;
  border-radius: 6px;
  padding-top: 50px;
  padding-bottom: 60px;
  padding-left: 5%;
  padding-right: 5%;
  overflow: visible;
}

.StartStatsInnerMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 28%;
}

.StartStatsTitleMobile {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
}

.ProjectPreviewOuterMobile {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3%;
  padding-right: 3%;
}

.ProjectPreviewImgMobile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.ProjectPreviewInnerMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin-left: 5%;
}

.ProjectPreviewTitleMobile {
  font-size: 9px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ProjectPreviewTextMobile {
  font-size: 7px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ReviewsPreviewOuterMobile {
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3%;
  padding-right: 3%;
  flex: 1;
}

.ReviewsPreviewInnerMobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
}

.ReviewsPreviewSubMobile {
  font-size: 7px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ReviewsPreviewTextMobile {
  font-size: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  /* number of lines to show */
  line-clamp: 8;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}

.RatingsPreviewOuterMobile {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3%;
  padding-right: 3%;
  flex: 1;
}

@media (max-width: 800px) {
  .StartStatsTitleMobile {
    font-size: 16px;
  }

  .ProjectPreviewTitleMobile {
    font-size: 7px;
  }

  .ProjectPreviewTextMobile {
    font-size: 6px;
  }

  .ReviewsPreviewSubMobile {
    font-size: 6px;
  }

  .ReviewsPreviewTextMobile {
    font-size: 6px;
  }

  .StartStatsMobile {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 600px) {
  .StartStatsTitleMobile {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .StartStatsTitleMobile {
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: -10px;
  }
  .ProjectPreviewTitleMobile {
    font-size: 10px;
  }

  .ProjectPreviewTextMobile {
    font-size: 9px;
  }

  .ReviewsPreviewSubMobile {
    font-size: 9px;
  }

  .ReviewsPreviewTextMobile {
    font-size: 9px;
  }

  .StartStatsMobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    border-radius: 6px;
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .StartStatsInnerMobile {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .StartStatsTitleMobile {
    font-size: 20px;
  }

  .ProjectPreviewTitleMobile {
    font-size: 9px;
  }

  .ProjectPreviewTextMobile {
    font-size: 8px;
  }

  .ReviewsPreviewSubMobile {
    font-size: 8px;
  }

  .ReviewsPreviewTextMobile {
    font-size: 8px;
  }
}
