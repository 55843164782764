.Points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    color: #39393c;
    padding-top: 90px;
    padding-bottom: 50px;
    background-color: #FFF2AC;
    min-height: calc(60vh);
}

.PointsInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 78%;
    max-width: 1500px;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: visible;
}