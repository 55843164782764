.AchievementsPreview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 78%;
  max-width: 1500px;
}

.AchievementsPreviewInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
    flex-wrap: wrap;
  width: 77%;
}

.AchievementsPreviewItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(48% - 20px);
  background-color: #ffffff;
  border: 1px solid #39393c;
  border-radius: 6px;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
box-shadow: 0px 1.5px 1px #39393C;
}

.AchievementsPreviewItemImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 3px;
  overflow: hidden;
}

.AchievementsPreviewItemInner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-left: 10px;
    overflow: hidden;
}

.AchievementsPreviewItemTitle {
    text-align: left;
    flex: 1;
    color: #39393c;
    font-size: 14px;
    font-weight: 700;
    margin-right: 10px;
    margin: 0;
}

.AchievementsPreviewItemText {
    text-align: right;
    color: #47CCF6;
    font-size: 10px;
    font-weight: 600;
    margin: 0;
    margin-right: 10px;
    margin-bottom: -3px;
    max-width: 40%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.AchievementsLeaderItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #A6E7CF;
    border: 1px solid #39393c;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 20%;
    margin-top: 15px;
    box-shadow: 0px 1.5px 1px #39393C;
}

.AchievementsLeaderItemTitleOuter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
margin-bottom: 10px;
}

.AchievementsLeaderItemTitleArrowOuter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 15px;
}

.AchievementsLeaderItemTitle {
    color: #39393c;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
    text-align: center;
    width: calc(100% - 30px);
    text-decoration: underline;
}

.AchievementsLeaderItemImg {
    width: 50%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AchievementsLeaderItemName {
    color: #39393c;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    text-align: center;
    width: 80%;
    margin-bottom: 10px;
}

.AchievementsLeaderItemPoints {
    color: #39393c;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    text-align: center;
    width: 80%;
    background-color: #FFFBF3;
    border: 1px solid #39393c;
    border-radius: 6px;
    padding-top: 5px;
    padding-bottom: 5px;
}