.PointsMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    color: #39393c;
    padding-top: 80px;
    padding-bottom: 90px;
    background-color: #FFF2AC;
}

.PointsInnerMobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    max-width: 700px;
    padding-top: 40px;
    padding-bottom: 30px;
    overflow: visible;
}

@media (max-width: 500px) {

.PointsMobile {
        padding-top: 40px;
    }
}