.StartSearchMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: #39393c;
  background-color: #fff2ac;
  margin-top: 30px;
  margin-bottom: 60px;
}

.StartSearchOuterMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
    max-width: 800px;
  height: 100%;
}

.StartSearchTopMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  background-color: #fffbf3;
  border: 1px solid #39393c;
  border-radius: 6px 6px 0px 0px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.StartSearchTitleMobile {
  font-size: 30px;
  width: 90%;
  color: #39393c;
  text-align: center;
  line-height: 140%;
  margin: 0;
  font-family: "Kensington", sans-serif;
  letter-spacing: 1px;
  margin-bottom: -3px;
}

.StartSearchTextMobile {
  font-size: 14px;
  width: 90%;
  color: #39393c;
  text-align: center;
  margin: 0;
}

.StartSearchInputMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 75%;
  max-width: 900px;
  margin-top: 40px;
}

.StartSearchResultsMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  background-color: #f5f2eb;
  border: 1px solid #39393c;
  border-radius: 0px 0px 6px 6px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-width: 0px;
}

.StartSearchResultsInnerMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 98%;
  max-width: 1500px;
  flex-wrap: wrap;
}

.StartSearchResultsItemMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(33% - 40px);
  margin: 10px;
  background-color: #fdfbf7;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 2px 4px 0px rgba(74, 74, 74, 0.05);
}

.StartSearchResultsItemTopMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100%);
  margin-top: 10px;
}

.StartSearchResultsItemTopTextOuterMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
}

.StartSearchResultsItemTopTextInnerMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.StartSearchResultsItemImgMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.StartSearchResultsItemTitleMobile {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.StartSearchResultsItemCategoryMobile {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: right;
    text-decoration: none;
    margin: 0;
    margin-left: 0px;
    margin-right: 5px;
    margin-top: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.StartSearchResultsItemTextMobile {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
    line-height: 1.4;
    width: 100%;
}

.StartSearchResultsItemBadgeMobile {
  width: 13px;
  min-width: 13px;
  margin-left: 5px;
  margin-top: 4px;
  margin-bottom: 0px;
  overflow: visible;
}

.StartSearchResultsItemBadgeRuggedMobile {
  width: 17px;
  min-width: 17px;
  margin-left: 5px;
  overflow: visible;
  margin-top: 4px;
  margin-bottom: 0px;
}

.StartSearchResultsExpandButtonMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-top: 10px;
  background-color: #fdfbf7;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 2px 4px 0px rgba(74, 74, 74, 0.05);
  cursor: pointer;
}

.ProjectListRatingItemMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.ProjectListRatingItemInnerMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.ProjectListRatingItemRatingMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}

.ProjectListRatingItemRatingTextMobile {
    font-size: 9px;
    font-weight: 500;
    margin: 0px;
    margin-right: 3px;
    margin-left: 3px;
    white-space: nowrap;
    text-decoration: none;
    text-align: left;
}

.ProjectListRatingItemRatingTextAltMobile {
    font-size: 9px;
    font-weight: 500;
    margin: 0px;
    margin-right: 3px;
    margin-left: 3px;
    white-space: nowrap;
    text-decoration: none;
    text-align: left;
    margin-top: 6.5px;
    margin-bottom: 4px;
}

.ProjectListRatingItemRatingMobile svg {
    width: 8px;
    margin-top: 1px;
}

@media (max-width: 800px) {
  .StartSearchTitleMobile {
    font-size: 25px;
  }
  .StartSearchTextMobile {
    font-size: 12px;
  }
    .StartSearchResultsItemMobile {
      width: calc(49% - 40px);
      margin: 10px
    }
}

@media (max-width: 600px) {
  .StartSearchTitleMobile {
    font-size: 22px;
  }
  .StartSearchTextMobile {
    font-size: 11px;
  }
.StartSearchInputMobile {
    width: 85%;
}
}

@media (max-width: 500px) {
  .StartSearchResultsItemMobile {
    width: calc(100% - 40px);
    margin: 10px
  }
}

@media (max-width: 400px) {
    .StartSearchTitleMobile {
        font-size: 18px;
    }

    .StartSearchTextMobile {
        font-size: 10px;
    }

    .StartSearchResultsItemMobile {
        width: calc(100% - 40px);
        margin: 10px
    }
        .StartSearchResultsItemTextMobile {
          height: 64px;
          -webkit-line-clamp: 4;
          /* number of lines to show */
          line-clamp: 4;
          line-height: 1.6;
        }
}
