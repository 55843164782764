.searchInputOuter {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  margin-top: 26px;
}

.autocompleteResultHeader {
  font-size: 90%;
  font-weight: 700;
  color: rgba(57, 57, 60, 0.8);
  text-align: left;
  padding: 10px;
  margin: 0;
  background-color: #fdfcf8;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.autocompleteResultTitle {
  font-size: 105%;
  font-weight: 500;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-right: 16px;
}

.autocompleteResultText {
  font-size: 95%;
  font-weight: 400;
  color: rgba(57, 57, 60, 0.8);
  text-align: right;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.listingCount {
  width: 100%;
  background-color: #fffbf3;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(35, 35, 35, 0.2);
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #39393c;
  margin-bottom: 30px;
}

.listingCountTitle {
  font-size: 5vw;
  font-weight: 600;
  color: #39393c;
  text-align: right;
  min-width: 16%;
  padding: 0;
  margin: 0;
  margin-left: 14px;
  margin-right: 10px;
}

.listingCountSub {
  font-size: 3vw;
  font-weight: 400;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
}

.listingTable {
  width: 88%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  margin-top: -70px;
  margin-bottom: -70px;
}

.listingTableTop {
  width: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  background-color: #fff2ac;
  border: 1px solid #39393c;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 2px 1px #39393c;
}

.listingTable .listingCountTitle {
  width: 94%;
  text-align: left;
  font-size: 1.5vw;
}

.listingTableTitle {
  font-size: 35px;
  font-weight: 600;
  color: #39393c;
  width: 94%;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-left: 14px;
  margin-right: 10px;
}

.listingTableSub {
  width: 93%;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  color: #39393c;
  margin-top: 0px;
  margin-bottom: 10px;
}

.listingTableButtons {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.listingTableButton {
  width: 23%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #39393c;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #39393c;
  border-radius: 20px;
}

.listingTableInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  margin-top: -10px;
}

.listingTableInnerDt {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  padding-top: 14px;
}

.dialogInner {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  background-color: #fffbf3;
  margin-bottom: 0px;
  flex: 1;
}

.appDialogClose {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  color: #39393c;
  z-index: 2000;
  background-color: #FFF2AC;
  padding-top: 2px;
  padding-bottom: 0px;
}

.listingDialog {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #39393c;
  flex: 1;
  margin-top: 40px;
  border-radius: 10px;
}

.loadingDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #39393c;
  flex: 1;
  width: 100%;
}

.listingDialogHeader {
  width: 86%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding-top: 30px;
  padding-bottom: 15px;
}

.appHeaderLogo {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.listingDialogHeaderName {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.listingDialogHeaderSocial {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.listingDialogHeaderTitle {
  text-align: left;
  font-size: 4.8vw;
  font-weight: 500;
  color: #39393c;
  padding: 0;
  margin: 0;
  margin-left: 2vw;
  max-width: 68%;
}

.listingDialogHeaderSocialLink {
  width: 9%;
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.listingDialogHeaderSocialIcon {
  width: 100%;
  height: auto;
  opacity: 0.8;
}

.listingWebsiteLink {
  text-align: left;
  font-size: 3vw;
  font-weight: 500;
  color: #35addb;
  padding: 0;
  margin: 0;
  width: 86%;
  text-decoration: none;
}

.listingDialogBody {
  width: 94%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  color: #39393c;
  margin-top: 10px;
  background-color: #fffbf3;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(35, 35, 35, 0.2);
  padding-top: 20px;
  padding-bottom: 15px;
}

.listingDialogDesc {
  text-align: left;
  font-size: 3.2vw;
  font-weight: 300;
  color: #39393c;
  margin-left: 4%;
  margin-bottom: 20px;
  margin-right: 4%;
  width: 92%;
}

.listingDialogDescSocial {
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4%;
  margin-bottom: 15px;
  margin-right: 4%;
}

.listingDialogTag {
  text-align: center;
  font-size: 3vw;
  font-weight: 400;
  color: #39393c;
  padding: 0px 5px 0px 5px;
  margin-left: 4%;
  border: 1px solid #39393c;
  border-radius: 60px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.radioButtons {
  width: 91%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 4%;
  margin-right: 5%;
}

.listingCountDt {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #39393c;
  flex: 1;
  padding-top: 30px;
}

.listingCountDtInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listingCountTitleDt {
  font-size: 230%;
  font-weight: 700;
  color: #39393c;
  text-align: center;
  width: 90%;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.listingCountSubDt {
  font-size: 110%;
  font-weight: 400;
  color: #39393c;
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: -4px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: -1px;
  padding-bottom: -1px;
}

.listingTableButtonDt {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 80%;
  font-weight: 600;
  color: #39393c;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.listingTableItemTitleDt {
  font-size: 80%;
  font-weight: 600;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-left: 14px;
}

.listingTableItemSubDt {
  font-size: 80%;
  font-weight: 500;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-left: 14px;
}

.listingTableItemInfoDt {
  font-size: 80%;
  font-weight: 500;
  color: #39393c;
  text-align: center;
  flex: 1;
  padding: 0;
  margin: 0;
}

@media (max-width: 1800px) {
  .listingTableTitle {
    font-size: 30px;
  }

  .listingTableSub {
    font-size: 12px;
  }

  .listingTableButton {
    font-size: 11px;
  }
}

@media (max-width: 1200px) {
    .listingTableTitle {
        font-size: 25px;
    }

    .listingTableSub {
        font-size: 11px;
    }

    .listingTableButton {
        font-size: 10px;
    }
}

@media (max-width: 900px) {
    .listingTableTitle {
        font-size: 20px;
    }

    .listingTableSub {
        font-size: 9px;
    }

    .listingTableButton {
        font-size: 9px;
    }
}

@media (max-width: 600px) {
    .listingTableTitle {
        font-size: 15px;
    }

    .listingTableSub {
        font-size: 8px;
    }

    .listingTableButton {
        font-size: 7px;
    }
}